import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { MatSelectModule } from '@angular/material/select';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieService } from 'ngx-cookie-service';
import { register } from 'swiper/element/bundle';

import {
  MatMomentDateModule,
  MomentDateModule,
} from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AdminHeaderComponent } from './admin-header/admin-header.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { LangInterceptor } from './interceptors/lang.interceptor';
import { RefreshTokenInterceptor } from './interceptors/refresh-token.interceptor';

import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ModeratorHeaderComponent } from './moderator-header/moderator-header.component';

register();
@NgModule({
  declarations: [AppComponent, AdminHeaderComponent, ModeratorHeaderComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatSelectModule,
    HttpClientModule,
    MatNativeDateModule,
    MatDialogModule,
    MomentDateModule,
    MatMomentDateModule,
    MatExpansionModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatDatepickerModule,
    GoogleTagManagerModule.forRoot({
      id: 'GTM-MJ4MW93',
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'en',
    }),
  ],
  exports: [],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: LangInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
