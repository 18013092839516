import { CanActivateFn, Router } from '@angular/router';
import { AdminInfoService } from '../services/admin-info.service';
import { inject } from '@angular/core';
import { LoginService } from '../services/login.service';

export const adminGuard: CanActivateFn = (route, state) => {
  const adminInfoService = inject(AdminInfoService);
  const router = inject(Router);
  const loginService = inject(LoginService);

  if (!adminInfoService.isAdmin()) {
    loginService.logOut();
  }

  return adminInfoService.isAdmin() ? true : router.navigate(['']);
};
